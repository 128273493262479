import { NgModule } from '@angular/core';
import { AppConfirmService } from './app-confirm.service';
import { AppConfirmComponent } from './app-confirm.component';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [ MaterialModule,  CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [AppConfirmComponent],
    providers: [AppConfirmService],
    entryComponents: [AppConfirmComponent],
    exports: [ AppConfirmComponent ]
  })
  export class AppConfirmModule { }
